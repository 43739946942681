<script>
  const text = "Get Started";
</script>

<style lang="scss">
  @import "../../sass/variables.scss";

  .call-to-action {
    position: relative;
    top: -23px;
  }

  .call-to-action:hover {
    & > span {
      color: $black;
    }
    & > svg > path {
      fill: $white;
    }
  }

  span {
    color: $white;
    position: relative;
    top: 32px;
    left: 24px;
  }
</style>

<div class="call-to-action">
  <span>{text}</span>

  <svg
    width="139"
    height="41"
    viewBox="0 0 139 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.8829 0H139V27.3498L120.256 44H0L0 14.2872L14.8829 0Z"
      fill="#FF5933"></path>
  </svg>
</div>
