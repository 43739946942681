<script>
  import { onMount } from "svelte";
  import * as PIXI from "pixi.js";
  PIXI.utils.skipHello();
  import gsap from "gsap";
  import throttle from "raf-throttle";

  const DOT_COLOR = 0xfe5832;
  const DOT_RADIUS = 1; // Particle size
  const STRENGTH = 2000; // Repulsion strength
  const SPACING = 16; // Distance between particles
  const CUTOFF = 25; // Maximum repulsion distance
  const REPEL = true; // repel or attract?
  const EASE = "expo";
  const DURATION = 1;
  const RANDOM_DECIMAL = Math.random(); // random offset to perturb dot position to mitigate floating-point errors

  let mounted;

  let pixiContainer, w, h;
  let pixi;
  let gTO;
  let aspectRatio;

  let graphics = [];
  let dotsStatic = [];

  let tickerTimeout;

  /**
   * Returns an angle from horizontal, based on Web Canvas coordinate system
   *
   * +ve x-axis is left-to-right
   *
   * +ve y-axis is top-to-bottom
   *
   * @param {number} x - horizontal displacement of a point from origin
   * @param {number} y - verticle displacement of a point from origin
   */
  const getAngle = (x, y) => {
    /* 
          x->
        y
        |
        v

        180-270 _|_ 270-360
        180-90   |  90-0
    */

    if (x > 0 && y > 0) {
      return Math.atan(y / x);
    } else if (x > 0 && y < 0) {
      return 2 * Math.PI + Math.atan(y / x);
    } else {
      return Math.PI + Math.atan(y / x);
    }
  };

  /**
   * Moves all particles back to their original 'static' positions
   */
  const resetPositions = () => {
    if (mounted) {
      graphics.forEach((dot, index) => {
        gsap.to(dot, {
          x: dotsStatic[index].x,
          y: dotsStatic[index].y,
          duration: DURATION,
          ease: EASE,
        });
      });

      // Stop render loop
      tickerTimeout = setTimeout(() => {
        pixi.ticker.stop();
      }, DURATION * 1000);
    }
  };

  /**
   * Uses mouse event to create a 'repulsion' effect for each particle.
   * @param e - mouse event which has `pageX` and `pageY` attributes
   * @param {number} strength - value which amplifies effect
   */
  const updatePositions = (e, strength) => {
    if (mounted && e.pageY <= pixi.screen.height) {
      // Resume render loop
      clearTimeout(tickerTimeout);
      pixi.ticker.start();

      // Particles move interactively
      graphics.forEach((dot, index) => {
        // Get displacement x,y to cursor from initial particle position
        const cursor_x = e.pageX - dotsStatic[index].x;
        const cursor_y = e.pageY - dotsStatic[index].y;

        // Get straight-line displacement to cursor
        let cursor_r = Math.sqrt(cursor_x * cursor_x + cursor_y * cursor_y);
        // Add cap to prevent dots from going very far away (due to small denominator)
        cursor_r = Math.max(cursor_r, CUTOFF);

        // Get 360 angle of cursor to particle
        const cursor_theta = getAngle(cursor_x, cursor_y);
        // If repulsion, particle should move in opposite direction to cursor
        const virtual_theta = cursor_theta - (REPEL ? Math.PI : 0);

        // Coordinates where particle should visually end up
        const virtual_x = (Math.cos(virtual_theta) / cursor_r) * strength;
        const virtual_y = (Math.sin(virtual_theta) / cursor_r) * strength;

        // Use GSAP to smooth animation to virtual position
        gsap.to(dot, {
          x: dotsStatic[index].x + virtual_x,
          y: dotsStatic[index].y + virtual_y,
          duration: DURATION,
          ease: EASE,
        });
      });
    }
  };

  /**
   * Callback to run on each pixel along a raster line.
   *
   * @callback RasterCallback
   * @param {number} index - pixel position along line
   * @param {number} length - total pixels along line
   */

  /**
   * Scans through canvas pixels in horizontal direction, running `callback` for each pixel
   * @param {RasterCallback} callback
   */
  const horizontalRaster = (callback) => {
    // This only really runs once, but it could be more optimised to increment at interval equal to spacing

    for (let index = 1; index < pixi.screen.width; index++) {
      callback(index, pixi.screen.width);
    }
  };

  /**
   * Scans through canvas pixels in vertical direction, running `callback` for each pixel
   * @param {RasterCallback} callback
   */
  const verticalRaster = (callback) => {
    // This only really runs once, but it could be more optimised to increment at interval equal to spacing

    for (let index = 1; index < pixi.screen.height; index++) {
      callback(index, pixi.screen.height);
    }
  };

  // const getRandomPosition = (width, height) => {
  //   const x = Math.random() * width;
  //   const y = Math.random() * height;

  //   return { x, y };
  // };

  /**
   * Returns x coordinate which fits dot matrix pattern (every odd line is offset by half the spacing)
   * @param x
   * @param y
   * @param spacing
   */
  const getXPos = (x, y, spacing) => {
    if (y % (spacing * 2) === 0) {
      return x;
    }
    return x - spacing / 2;
  };

  /**
   * Returns y coordinate which fits dot matrix pattern (horizontal lines are spaced evenly)
   * @param x
   * @param y
   * @param spacing
   */
  const getYPos = (x, y, spacing) => {
    return y - spacing / 2;
  };

  /**
   * Adds point to canvas as PIXI graphic object
   * @param x - x-coordinate
   * @param y - y-coordinate
   * @param index - array position in `graphics` to override with this PIXI graphic object
   */
  const addDot = (x, y, index) => {
    graphics[index] = new PIXI.Graphics();
    graphics[index].beginFill(DOT_COLOR);
    graphics[index].drawCircle(0, 0, DOT_RADIUS);
    graphics[index].endFill();

    // Set initial position for each dot
    graphics[index].position.set(x, y);

    // This small perturbation to static position prevents dots spazzing out when cursor is be positioned on exact pixel of dot.
    x += RANDOM_DECIMAL;
    y += RANDOM_DECIMAL;

    // Record initial position for each dot
    dotsStatic[index] = { x, y };

    pixi.stage.addChild(graphics[index]);
  };

  /**
   * Conditionally adds particle to canvas only if it fits within DNA Pathways logo
   * @param x
   * @param y
   * @param width
   * @param height
   * @param index
   */
  const attemptPlace = (x, y, width, height, index) => {
    // This is essentially two piecewise functions for the top and bottom curves of the logo.
    // Logo can be split into 3 regions, moving left to right.
    const isInRegion1 = x < 0.5 * width;
    const isInRegion2 = !isInRegion1 && x < 0.6 * width;
    const isInRegion3 = !isInRegion1 && !isInRegion2 && x < 0.8 * width;

    const gradient = -1 / aspectRatio;

    // Scales from origin, but need to counteract
    const scalingNormaliser = -width / 2 + height / 2;

    if (isInRegion1) {
      // Check in range
      const y_top =
        (gradient * x + 0.9 * height) * aspectRatio + scalingNormaliser;
      const y_bot = 0.6 * height * aspectRatio + scalingNormaliser;

      const isInRange = y > y_top && y < y_bot;

      if (isInRange) {
        const xPos = getXPos(x, y, SPACING);
        const yPos = getYPos(x, y, SPACING);

        addDot(xPos, yPos, index);
        return true;
      }
    }

    if (isInRegion2) {
      // Check in range
      const y_top =
        (gradient * x + 0.9 * height) * aspectRatio + scalingNormaliser;
      const y_bot = 0.7 * height * aspectRatio + scalingNormaliser;

      const isInRange = y > y_top && y < y_bot;

      if (isInRange) {
        const xPos = getXPos(x, y, SPACING);
        const yPos = getYPos(x, y, SPACING);

        addDot(xPos, yPos, index);
        return true;
      }
    }

    if (isInRegion3) {
      // Check in range
      const y_top = 0.3 * height * aspectRatio + scalingNormaliser;
      const y_bot =
        (gradient * x + 1.3 * height) * aspectRatio + scalingNormaliser;

      const isInRange = y > y_top && y < y_bot;

      if (isInRange) {
        const xPos = getXPos(x, y, SPACING);
        const yPos = getYPos(x, y, SPACING);

        addDot(xPos, yPos, index);
        return true;
      }
    }

    return false;
  };

  /**
   * Calculates particle positions and renders them
   */
  const setupGraphics = () => {
    aspectRatio = pixi.screen.width / pixi.screen.height;

    let index = 0;

    verticalRaster((y, height) => {
      horizontalRaster((x, width) => {
        // Test if multiple of spacing and also in domain
        if (
          x % SPACING === 0 &&
          y % SPACING === 0 &&
          x > 0.4 * width &&
          x < 0.8 * width
        ) {
          const didPlace = attemptPlace(x, y, width, height, index);
          if (didPlace) index++;
        }
      });
    });
  };

  /**
   * Refreshes canvas using latest canvas width and height values
   */
  const resetGraphics = () => {
    // Does this help reduce thrashing?
    clearTimeout(gTO);

    // Delay makes it more likely that graphics are correctly updated when window is maximised, otherwise it will use the wrong w and h values
    gTO = setTimeout(() => {
      pixi.stage.removeChildren();
      setupGraphics();
    }, 100);
  };

  /**
   * Refresh canvas whenever window is resized
   */
  $: if (w || h) resetGraphics();

  onMount(() => {
    requestAnimationFrame(() => {
      // PIXI setup
      pixi = new PIXI.Application({
        resizeTo: pixiContainer,
        width: w,
        height: h,
        antialias: true,
        // transparent: true,
        backgroundColor: 0x141414,
      });

      pixi.ticker.maxFPS = 60;

      pixiContainer.appendChild(pixi.view);

      setupGraphics();

      mounted = true;
    });
  });
</script>

<style>
  .pixi-container {
    position: absolute !important;
    z-index: -1;
    width: 100%;
    height: 100%;
  }
</style>

<div
  class="pixi-container"
  bind:this="{pixiContainer}"
  bind:clientWidth="{w}"
  bind:clientHeight="{h}">
</div>

<svelte:window
  on:mousemove="{(e) => throttle(updatePositions(e, STRENGTH))}"
  on:mouseout="{resetPositions}" />
