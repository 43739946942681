<script>
  let w,
    isMobile = false,
    isTablet = false;

  $: if (w <= 1024) {
    isMobile = true;
    if (w >= 768) {
      isTablet = true;
    } else {
      isTablet = false;
    }
  } else {
    isMobile = false;
    isTablet = false;
  }
</script>

<style lang="scss">
  @import "../../sass/variables.scss";

  .projects__outer {
    padding-top: 15vw;
    padding-bottom: 15vw;
    background-color: $white;

    .projects-title {
      margin: 0 5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      white-space: nowrap;
    }

    .projects {
      display: flex;
      align-items: stretch;
      justify-content: center;
      flex-wrap: wrap;
      margin: 0 5rem;

      .projects-group {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .project-item {
          margin: 2rem;
          width: 300px;

          .content {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          }
        }
      }
    }
  }

  img.logo-clip {
    width: 300px;
    height: 300px;
    object-fit: cover;
    clip-path: polygon(
      50% 0%,
      100% 0%,
      100% 50%,
      50% 100%,
      25% 100%,
      25% 75%,
      0% 75%,
      0% 50%
    );
  }

  .button__outer {
    margin-top: 1.5rem;

    .button {
      border: none;
      &:focus {
        color: $white;
      }
    }
  }

  .content {
    h1,
    h2,
    p,
    strong {
      color: $black;
    }
  }

  h1.title.is-huge {
    max-width: 40rem;
    font-size: 5rem;
    letter-spacing: -0.2rem;
  }

  h1.title.is-huge-mobile {
    font-size: 3rem;
    letter-spacing: -0.15rem;
  }
</style>
<!-- HIDE PROJECTS 
<div class="projects__outer">
  <div class="projects-title content">
    <h1 class="title is-huge" class:is-huge-mobile="{isMobile}">Projects</h1>
  </div>

  <div class="projects">
    <div class="projects-group">
      <div class="project-item">
        <-- Creation Station ->
        <div class="content">
          <div>
            <img
              class="logo-clip"
              src="/assets/img/creationstation.png"
              alt="Creation Station Logo" />
            <h2>Creation Station</h2>
            <p>
              Creation Station is your complete digital co-creation workstation.
              Collect data, research, come up with brilliant insights, ideate,
              make decisions and innovate. Creation Station makes it easy to
              collaborate remotely and manage Design Thinking and creation
              projects from start to finish.
            </p>
          </div>
          <div class="button__outer">
            <a
              href="https://creationstation.io"
              class="button is-rounded"
              target="_blank">Try It</a>
          </div>
        </div>
      </div>
      <div class="project-item">
        <-- CommSync ->
        <div class="content">
          <div>
            <img
              class="logo-clip"
              src="/assets/img/commsync.png"
              alt="Community Sync Logo" />
            <h2>Community Sync</h2>
            <p>
              Community Sync (or ‘CommSync’) synchronises activities between
              users, no matter where they are in the world, no matter their
              connection speed. Join us as we discover new ways to synchronise
              together.
            </p>
          </div>
          <div class="button__outer">
            <a
              href="https://commsync.io"
              class="button is-rounded"
              target="_blank">Try It</a>
          </div>
        </div>
      </div>
    </div>

    <div class="projects-group">
      <div class="project-item">
        <-- ONE Day ->
        <div class="content">
          <div>
            <img
              class="logo-clip"
              src="/assets/img/oneday.png"
              alt="ONE Day Logo" />
            <h2>ONE Day</h2>
            <p>
              ONE Day 11/11 is an interactive Festival of Oneness, and a huge
              experiment in mass connection and intention. Imagine if the whole
              world stopped for a moment and took a breath together...
            </p>
          </div>
          <div class="button__outer">
            <a
              href="https://oneday1111.com"
              class="button is-rounded"
              target="_blank">Join Us</a>
          </div>
        </div>
      </div>
      <div class="project-item">
        <-- HCD Article ->
        <div class="content">
          <div>
            <img
              class="logo-clip"
              src="/assets/img/hcd-article.png"
              alt="Sticky notes stuck on a computer monitor" />
            <h2>Research: State of Human Centred Design</h2>
            <p>
              What happened to HCD in 2020? We conducted exploratory research to
              learn about how the practice evolved in 2020 due to the global
              shift to remote-work settings.
            </p>
          </div>
          <div class="button__outer">
            <a
              href="/blog/meeting-the-universe-halfway?projects=true"
              class="button is-rounded"
              target="_blank">Read More</a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <hr />

  <div class="projects">
    <div class="projects-group">
      <div class="project-item">
        <-- Sourdough ->
        <div class="content">
          <div>
            <img
              class="logo-clip"
              src="/assets/img/sourdough.png"
              alt="Sourdough Business Pathways logo" />
            <h2>Sourdough Pre-Accelerator Program</h2>
            <p>
              Do you have an early-stage idea or business that’s aiming to be a
              force for good in the world? Sourdough Pre-Accelerator is designed
              to support founders to take their emerging business ideas from
              concept through to MVP. Founders are supported by expert
              facilitators and mentors throughout the program and have the
              opportunity to pitch to our region's business and investor
              community.
            </p>
          </div>
          <div class="button__outer">
            <a
              href="https://sbp.org.au/sourdough-pre-accelerator/"
              class="button is-rounded"
              target="_blank">Apply Now</a>
          </div>
        </div>
      </div>
      <div class="project-item">
        <-- SCU ->
        <div class="content">
          <div>
            <img
              class="logo-clip"
              src="/assets/img/scu.png"
              alt="Southern Cross University logo" />
            <h2>SCU Bright Futures Mentoring Program</h2>
            <p>
              This exciting program is designed to match students with alumni in
              a related industry and provides a learning experience for students
              that connects the classroom to the ‘real world’.
            </p>
          </div>
          <div class="button__outer">
            <a
              href="https://www.scu.edu.au/current-students/services-and-support/careers/bright-futures-mentoring-program/"
              class="button is-rounded"
              target="_blank">Apply Now</a>
          </div>
        </div>
      </div>
    </div>

    <div class="projects-group">
      <div class="project-item">
        <-- Byron's Army ->
        <div class="content">
          <div>
            <img
              class="logo-clip"
              src="/assets/img/byron_entrepreneurs_sqr.png"
              alt="Photograph of Byron's Army of Entrepreneurs members" />
            <h2>Byron's Army of Entrepreneurs</h2>
            <p>
              Byron's Army of Entrepreneurs events provide a great atmosphere
              which enables us to learn, support each other and network, and
              help great projects come to fruition, empowering each other and
              the community at large.
            </p>
          </div>
          <div class="button__outer">
            <a
              href="https://byronimpact.social"
              class="button is-rounded"
              target="_blank">Join Us</a>
          </div>
        </div>
      </div>
      <div class="project-item">
        <-- LiT DT Handbook ->
        <div class="content">
          <div>
            <img
              class="logo-clip"
              src="/assets/img/dt-handbook.png"
              alt="Design Thinking Learning Program handbook cover image" />
            <h2>Design Thinking Learning Program</h2>
            <p>
              Partnering with <em>Leaders in Technology</em>, we designed a 12
              month HCD learning program for senior leaders from 9 large
              organisations. Participants learned how to apply HCD through a
              hands-on project for a non-profit BullyZero. Learn more about the
              program in the Handbook.
            </p>
          </div>
          <div class="button__outer">
            <a
              href="/assets/pdf/dt-handbook.pdf"
              class="button is-rounded"
              target="_self"
              download="Design Thinking Handbook">Get Handbook</a>
          </div>
        </div>
      </div>
    </div>
  </div> 
</div> -->

<svelte:window bind:innerWidth="{w}" />
