
/**
 * @roxi/routify 2.15.0
 * File generated Tue May 21 2024 02:47:39 GMT+0000 (Coordinated Universal Time)
 */

export const __version = "2.15.0"
export const __timestamp = "2024-05-21T02:47:39.319Z"

//buildRoutes
import { buildClientTree } from "@roxi/routify/runtime/buildRoutes"

//imports
import _blog_index from '../src/pages/blog/index.svx'
import _blog_meetingTheUniverseHalfway from '../src/pages/blog/meeting-the-universe-halfway.svx'
import _blog__layout from '../src/pages/blog/_layout.svelte'
import _privacyPolicy from '../src/pages/privacy-policy.svelte'
import _index from '../src/pages/index.svelte'
import __layout from '../src/pages/_layout.svelte'

//options
export const options = {}

//tree
export const _tree = {
  "root": true,
  "children": [
    {
      "isDir": true,
      "children": [
        {
          "ext": "svx",
          "isIndex": true,
          "isPage": true,
          "ownMeta": {
            "title": "Blog"
          },
          "meta": {
            "title": "Blog",
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/blog/index",
          "id": "_blog_index",
          "component": () => _blog_index
        },
        {
          "ext": "svx",
          "isPage": true,
          "ownMeta": {
            "title": "Meeting The Universe Halfway"
          },
          "meta": {
            "title": "Meeting The Universe Halfway",
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/blog/meeting-the-universe-halfway",
          "id": "_blog_meetingTheUniverseHalfway",
          "component": () => _blog_meetingTheUniverseHalfway
        }
      ],
      "isLayout": true,
      "path": "/blog",
      "id": "_blog__layout",
      "component": () => _blog__layout
    },
    {
      "isPage": true,
      "path": "/privacy-policy",
      "id": "_privacyPolicy",
      "component": () => _privacyPolicy
    },
    {
      "isIndex": true,
      "isPage": true,
      "ownMeta": {
        "title": "Design for life."
      },
      "meta": {
        "title": "Design for life.",
        "recursive": true,
        "preload": false,
        "prerender": true
      },
      "path": "/index",
      "id": "_index",
      "component": () => _index
    }
  ],
  "isLayout": true,
  "path": "/",
  "id": "__layout",
  "component": () => __layout
}


export const {tree, routes} = buildClientTree(_tree)

