<script>
  import Scroller from "@sveltejs/svelte-scroller";

  import WhatText from "./WhatText.svelte";
  import WhatPolkaLogo from "./WhatPolkaLogo.svelte";
  import OnlineIcon from "./svg/OnlineIcon.svelte";
  import HammerIcon from "./svg/HammerIcon.svelte";
  import EcosystemIcon from "./svg/EcosystemIcon.svelte";
  import MagnifyingIcon from "./svg/MagnifyingIcon.svelte";
  import SendIcon from "./svg/SendIcon.svelte";
  import LightbulbIcon from "./svg/LightbulbIcon.svelte";
  import TargetIcon from "./svg/TargetIcon.svelte";
  import PencilIcon from "./svg/PencilIcon.svelte";
  import LaptopIcon from "./svg/LaptopIcon.svelte";

  const DIGITAL_PRODUCTS = "Design, prototyping, development, and optimisation";
  const SYSTEMS_R_D =
    "Radical innovation of systems and their constitutive parts";
  const ORG_DESIGN =
    "Evolve your org. to become more life-centric and able to respond to complexity";

  const USER_RESEARCH = "Explore and identify core drivers, needs and wants";
  const IDEATION = "Generate ideas using a fail-proof methodology";
  const STRATEGY = "Co-create and consciously realise your vision and purpose";

  const TECH_DELIVERY =
    "Custom digital products & services, Information Architecture / Ontology, UX / UI, Analytics";
  const DESIGN_THINKING =
    "Operations, custom project design, workshop facilitation, on-job learning programs";
  const DISTRIBUTED_WORKFLOWS = "Create and grow distributed teams";

  let w,
    index,
    isMobile = false;

  $: if (w <= 1024) {
    isMobile = true;
  } else {
    isMobile = false;
  }
</script>

<style lang="scss">
  @import "../../sass/variables.scss";

  .what-container {
    background-color: $white;
    padding-top: 12rem;
    padding-bottom: 6rem;
    position: relative;
    z-index: 1;
  }

  .what-container-mobile {
    padding-top: 3rem;
  }

  .container-flex {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .title {
    color: $black;
  }

  h1.is-huge {
    max-width: 40rem;
    font-size: 5rem;
    letter-spacing: -0.2rem;
  }

  h1.is-huge-mobile {
    font-size: 3rem;
    letter-spacing: -0.15rem;
  }

  h2.is-large {
    max-width: 50vw;
    font-size: 3rem;
    letter-spacing: -0.1rem;
  }

  h2.is-large-mobile {
    font-size: 2rem;
  }

  h3.is-4 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }

  .what-paragraphs {
    max-width: 37rem;
    color: $black;
    text-align: center;
  }

  .what-paragraphs.is-mobile {
    text-align: left;
  }

  section.section {
    transition: opacity 0.3s;
    padding: 4rem 1.5rem 2rem;
  }

  .section.inner {
    padding-bottom: 0;
  }

  section.hide-section {
    opacity: 0.25;
  }

  .icon-wrapper {
    min-height: 3rem;
  }

  .columns.is-desktop {
    width: 100%;
  }

  .columns {
    justify-content: space-evenly;
  }

  .column {
    max-width: 12rem;
    margin-bottom: 2rem;
  }

  h3.title.is-4 {
    min-height: 3.5rem;
    font-size: 1.2rem;
  }

  .what-section {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .what-we-do {
    font-size: 0.7rem;
  }

  .no-bottom-padding {
    padding-bottom: 0rem;
  }

  .heart-container {
    width: 6rem;
  }

  .heart-container.is-mobile {
    width: 4rem;
  }
</style>

<div class="what-container" class:what-container-mobile="{isMobile}">
  <WhatPolkaLogo />

  <!-- WE LOVE CREATING SYSTEMS -->

  <div class="section what-section">
    <div
      class="container container-flex heart-container"
      class:is-mobile="{isMobile}">
      <svg
        width="100%"
        viewBox="0 0 122 92"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M60.6783 91.3232L30.0331 60.6781L60.6783 30.0329L91.3234
          60.6781L60.6783 91.3232Z"
          fill="#151515"></path>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M30.7934 61.4385L0.148206 30.7933L30.7934 0.148096L61.4386
          30.7933L30.7934 61.4385Z"
          fill="#151515"></path>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M90.5631 61.4385L59.9179 30.7933L90.5631 0.148096L121.208
          30.7933L90.5631 61.4385Z"
          fill="#151515"></path>
      </svg>
    </div>
  </div>
  <div class="section what-section no-bottom-padding">
    <div class="container container-flex">
      <h6 class="title is-6 what-we-do">WHAT WE DO</h6>
    </div>
  </div>
  <div class="section what-section">
    <div class="container container-flex">
      <h1
        class="title is-huge"
        class:mobile-width="{isMobile}"
        class:is-huge-mobile="{isMobile}">
        Be the edge
      </h1>
    </div>
  </div>
  <div class="section what-section">
    <div class="container content what-paragraphs" class:is-mobile="{isMobile}">
      <WhatText />
    </div>
  </div>

  <Scroller bind:index>
    <div slot="foreground">
      <!-- Gives us a 0 index for opacity -->
      <section class="section"></section>

      <!-- DESIGN -->

      <section class="section" class:hide-section="{index !== 1}">
        <div class="container container-flex">
          <h2
            class="title is-large"
            class:mobile-width="{isMobile}"
            class:is-large-mobile="{isMobile}">
            Design
          </h2>
        </div>
        <div class="section inner">
          <div class="container-flex">
            <div class="columns" class:is-desktop="{!isMobile}">
              <div class="column">
                <div class="icon-wrapper">
                  <OnlineIcon />
                </div>
                <h3 class="title is-4">
                  Digital Products
                  <br />
                  & Services
                </h3>
                <p>{DIGITAL_PRODUCTS}</p>
              </div>
              <div class="column">
                <div class="icon-wrapper">
                  <SendIcon />
                </div>
                <h3 class="title is-4">Systems R&D</h3>
                <p>{SYSTEMS_R_D}</p>
              </div>
              <div class="column">
                <div class="icon-wrapper">
                  <EcosystemIcon />
                </div>
                <h3 class="title is-4">Organisational Design</h3>
                <p>{ORG_DESIGN}</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- <hr /> -->

      <!-- RESEARCH & IDEATION -->

      <section class="section" class:hide-section="{index !== 2}">
        <div class="container container-flex">
          <h2
            class="title is-large"
            class:mobile-width="{isMobile}"
            class:is-large-mobile="{isMobile}">
            Research & Ideation
          </h2>
        </div>
        <div class="section inner">
          <div class="container-flex">
            <div class="columns" class:is-desktop="{!isMobile}">
              <div class="column">
                <div class="icon-wrapper">
                  <MagnifyingIcon />
                </div>
                <h3 class="title is-4">
                  Customer / User <br /> Research
                </h3>
                <p>{USER_RESEARCH}</p>
              </div>
              <div class="column">
                <div class="icon-wrapper">
                  <LightbulbIcon />
                </div>
                <h3 class="title is-4">Ideation</h3>
                <p>{IDEATION}</p>
              </div>
              <div class="column">
                <div class="icon-wrapper">
                  <TargetIcon />
                </div>
                <h3 class="title is-4">Strategy</h3>
                <p>{STRATEGY}</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- <hr /> -->

      <!-- OPERATIONS & DELIVERY -->

      <section class="section" class:hide-section="{index !== 3}">
        <div class="container container-flex">
          <h2
            class="title is-large"
            class:mobile-width="{isMobile}"
            class:is-large-mobile="{isMobile}">
            Operations & Delivery
          </h2>
        </div>
        <div class="section inner">
          <div class="container-flex">
            <div class="columns" class:is-desktop="{!isMobile}">
              <div class="column">
                <div class="icon-wrapper">
                  <HammerIcon />
                </div>
                <h3 class="title is-4">Technical Delivery</h3>
                <p>{TECH_DELIVERY}</p>
              </div>
              <div class="column">
                <div class="icon-wrapper">
                  <PencilIcon />
                </div>
                <h3 class="title is-4">Design Thinking, Agile/LEAN</h3>
                <p>{DESIGN_THINKING}</p>
              </div>
              <div class="column">
                <div class="icon-wrapper">
                  <LaptopIcon />
                </div>
                <h3 class="title is-4">
                  Distributed <br /> Teams & Workflows
                </h3>
                <p>{DISTRIBUTED_WORKFLOWS}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

    <div slot="background"></div>
  </Scroller>
</div>

<svelte:window bind:innerWidth="{w}" />
