<script>
  import ServicesPolkaLogo from "../services/ServicesPolkaLogo.svelte";

  let w;
  let tabIndex = 0;
</script>

<style lang="scss">
  @import "../../sass/variables.scss";

  .book__outer {
    padding-top: 15vw;
    padding-bottom: 15vw;
    background-color: $black;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    .book {
      position: relative;
      display: flex;
      align-items: stretch;
      justify-content: center;
      z-index: 0;
      min-height: 50vh;
      width: 100%;
      margin: 0 5rem;

      &.is-mobile {
        display: block;
        margin: 0 2rem;
      }

      .book__background {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        align-self: center;
        width: 50%;
        height: calc(50vh + 10rem);
        right: 0;
        z-index: -1;

        svg {
          position: absolute;
          max-width: 90%;
        }

        .polka {
          position: absolute;
          width: 100%;
          height: 100%;
        }
      }

      .book__foreground {
        width: 100%;
        padding-right: 55%;

        &.is-mobile {
          padding-right: 0;
        }
      }
    }
  }

  .title {
    color: $white;
    letter-spacing: -0.02em;
  }

  a.button.is-success.is-rounded {
    margin-top: 2rem;
    border: none;
    color: $black;

    &:focus {
      color: $black;
    }
  }

  strong {
    color: inherit;
  }

  p {
    font-family: "BrownStd-Light";
  }

  .selector__outer {
    position: relative;
    left: -5rem;
    top: 0;

    .selector {
      li {
        margin-bottom: 1.5rem;
      }

      .arrow {
        display: inline-block;
        height: 80px;
        width: 100%;
        white-space: nowrap;
        position: relative;
        text-align: center;
        padding-top: 1.75em;
        line-height: 1.5em;
        padding-left: 1em;
        padding-right: 1em;
        background: $white;
        color: $black;
        cursor: pointer;
        &:after {
          border-left: 40px solid $white;
          transition: all 0.1s cubic-bezier(0.075, 0.82, 0.165, 1);
        }
        transition: all 0.1s cubic-bezier(0.075, 0.82, 0.165, 1);
      }

      .arrow:after {
        // the triangle
        content: "";
        position: absolute;
        border-bottom: 40px solid transparent;
        border-top: 40px solid transparent;
        top: 0;
        height: 0px;
        width: 0px;
        right: -40px;
      }

      .arrow:hover {
        background: $black;
        color: $white;
        &:after {
          // triangle hover
          border-left: 40px solid $black;
        }
      }

      .hover-green:hover {
        background: $green;
        color: $black;
        &:after {
          // triangle hover
          border-left: 40px solid $green;
        }
      }

      .active-green {
        background: $green;
        color: $black;
        &:after {
          // triangle hover
          border-left: 40px solid $green;
        }
      }
    }
  }

  .book-image__mobile {
    position: relative;
    margin: {
      top: 5rem;
      bottom: 5rem;
    }
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      max-width: 300px;
    }

    .polka {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      z-index: -1;
      transform: scale(1.2);
    }
  }
</style>

<!-- HIDE BOOK
  <div class="book__outer">
  <div class="book" class:is-mobile="{w <= 1216}">
    <-- Desktop ->
    {#if !(w <= 1216)}
      <div class="selector__outer">
        <div class="selector">
          <ul>
            <li>
              <div
                class="arrow hover-green"
                class:active-green="{tabIndex === 0}"
                on:click="{() => (tabIndex = 0)}">
                About Book
              </div>
            </li>
            <li>
              <div
                class="arrow hover-green"
                class:active-green="{tabIndex === 1}"
                on:click="{() => (tabIndex = 1)}">
                About Author
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div
        class="book__background rellax"
        data-rellax-percentage="0.5"
        data-rellax-speed="-2">
        <div
          class="polka rellax"
          data-rellax-percentage="0.5"
          data-rellax-speed="-4">
          <ServicesPolkaLogo />
        </div>

        <svg
          height="100%"
          viewBox="0 0 827 1254"
          class="rellax"
          data-rellax-percentage="0.5"
          xmlns="http://www.w3.org/2000/svg">
          <image
            width="827"
            height="1254"
            href="/assets/img/Utesheva_DesigningProducts.jpg"></image>
        </svg>
      </div>
    {/if}

    <div class="book__foreground" class:is-mobile="{w <= 1216}">
      <div class="content">
        {#if tabIndex === 0 || w <= 1216}
          <h1 class="title is-huge">Life, Design & Digital</h1>

          <p>
            Digital is evolving at an ever-increasing pace, and so is every part
            of our reality. As designers, it’s up to us to enhance users’
            experience and life for the better. <em
              >Designing Products for Evolving Digital Users</em>
            is a 21st century handbook that helps you do just that. By providing
            an evolutionary perspective to frame the exploration of UX (user experience),
            behavior patterns, digital communities and emerging trends, the book
            instills confidence and fact-based foundations for your creations.
          </p>
          <p>
            Design’s ultimate goal is improvement of a part of a system and its
            whole. While a myriad of material is available consisting of simple
            tips and tricks for optimal design,

            <em>Designing Products for Evolving Digital Users</em>
            is a rare and remarkable title that cohesively accounts for all environmental
            factors involved. Designers, futurists, students, and industry veterans
            alike have an abundance of insights and discovery ahead of them in
            <em>Designing Products for Evolving Digital Users</em>.
          </p>

          <a
            class="button is-success is-rounded"
            href="https://www.apress.com/gp/book/9781484263785"
            target="_blank">Get Book</a>
        {/if}

        {#if w <= 1216}
          <div class="book-image__mobile">
            <svg
              height="100%"
              width="100%"
              viewBox="0 0 827 1254"
              xmlns="http://www.w3.org/2000/svg">
              <image
                width="827"
                height="1254"
                href="/assets/img/Utesheva_DesigningProducts.jpg"></image>
            </svg>

            <div class="polka">
              <ServicesPolkaLogo />
            </div>
          </div>
        {/if}

        {#if tabIndex === 1 || w <= 1216}
          <h1 class="title is-huge">Design for Life</h1>

          <p>
            <strong> Anastasia Utesheva </strong>
            <br />
            Designer | DNA Pathways
          </p>

          <p>
            Anastasia has dedicated her career to improving quality of life
            through strategic design. Her experience working across startups,
            private sector organisations, and academia has shaped her pragmatic
            approach to conscious systemic change. Anastasia believes that the
            coevolution of humans and technology is core to transforming legacy
            ways of being and creating truly beautiful and regenerative ways of
            life. Anastasia specialises in shifting thought systems to empower
            creators to (re)design through empathy and core value alignment.
          </p>

          <a
            class="button is-success is-rounded"
            href="https://www.linkedin.com/in/anastasia-utesheva/"
            target="_blank">Connect</a>
        {/if}
      </div>
    </div>
  </div>
</div>-->

<svelte:window bind:innerWidth="{w}" />
