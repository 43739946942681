<script>
  import HeaderTagline from "./HeaderTagline.svelte";

  let w,
    isTablet = false;

  $: if (w <= 1024 && w >= 768) {
    isTablet = true;
  } else {
    isTablet = false;
  }
</script>

<style lang="scss">
  .logo-container {
    max-width: 50%;
    position: relative;
    z-index: 1;
    padding-top: 5%;
  }

  .mobile-header-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .mobile-header-container.is-tablet {
    padding-top: 10%;
  }
</style>

<div class="mobile-header-container" class:is-tablet="{isTablet}">
  <div class="logo-container">
    <img
      src="/assets/svg/logo-navbar.svg"
      alt="DNA Pathways logo is an arrow pointing diagonally up and to the right" />
  </div>
  <HeaderTagline />
</div>

<svelte:window bind:innerWidth="{w}" />
