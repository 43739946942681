<script>
  let year = new Date().getFullYear();
</script>

<style lang="scss">
  @import "../../sass/variables.scss";

  p {
    font-size: 0.9rem;
  }

  a {
    color: hsl(0, 0%, 29%);
    padding: 0;

    &:hover {
      color: #000;
    }
  }

  .tiny {
    font-size: 0.6rem;
  }
</style>

<p class="tiny">
  &copy; {year} DNA PATHWAYS PTY LTD. All rights reserved.
</p>
<p class="tiny">
  <a href="/privacy-policy">Privacy Policy</a>
</p>
