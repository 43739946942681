<script>
  import Watch from "./svg/watch/Watch.svg";
  import Arc1 from "./svg/watch/Arc1.svg";
  import Arc2 from "./svg/watch/Arc2.svg";
  import Center from "./svg/watch/Center.svg";
  import Arc3Evolve from "./svg/watch/Arc3Evolve.svg";
  import Arc4 from "./svg/watch/Arc4.svg";

  let w,
    isMobile = false;

  $: if (w <= 1024) {
    isMobile = true;
  } else {
    isMobile = false;
  }
</script>

<style lang="scss">
  @import "../../sass/variables.scss";
  @import "./scss/how.scss";

  .slide-container {
    position: relative;
    background-color: $purple;
    z-index: 2;
  }

  .title {
    color: $white;
    padding: 0 1rem;
  }

  .watch-label {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 12vw;
    padding-bottom: 12vw;
    color: $white;
    width: 100%;
  }

  .watch-label.under {
    padding-top: 12vw;
    padding-bottom: 0;
  }

  .watch-label.last {
    padding-top: 6vw;
    padding-bottom: 6vw;
  }

  .watch-label > * {
    text-align: center;
  }

  .label-heading {
    font-weight: bold;
  }

  .svg-expand:last-child {
    margin-bottom: 12vw;
  }

  .svg-expand.svg-evolve {
    margin-top: 6vw;
  }
</style>

<div class="slide-container">
  <div class="svg-flex-container" class:mobile-container="{isMobile}">
    {#if isMobile}
      <div class="section">
        <h6 class="title is-6 section-title">HOW WE WORK</h6>
      </div>
      <h1 class="title is-huge is-huge-mobile">Watch, Improve, Evolve</h1>
      <div class="svg-expand">
        {@html Arc1}
      </div>

      <div class="watch-label">
        <div class="label-heading">Day-to-Day Use</div>
        <p>Feedback from everyday use drives improvement.</p>
      </div>

      <div class="svg-expand">
        {@html Arc2}
      </div>

      <div class="watch-label">
        <div class="label-heading">Social Impact</div>
        <p>We monitor impact of designs on social dynamics and metrics.</p>
      </div>

      <div class="svg-expand">
        {@html Center}
      </div>

      <div class="watch-label under">
        <div class="label-heading">Environmental Impact</div>
        <p>
          We monitor environmental impact of designs to make sure we improve,
          not harm.
        </p>
      </div>

      <div class="svg-expand svg-evolve">
        {@html Arc3Evolve}
      </div>

      <div class="watch-label last">
        <div class="label-heading">Economic Impact</div>
        <p>
          We monitor economic performance to make sure we get formulas right.
        </p>
      </div>

      <div class="svg-expand">
        {@html Arc4}
      </div>
    {:else}
      {@html Watch}
    {/if}
  </div>
</div>

<svelte:window bind:innerWidth="{w}" />
