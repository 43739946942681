<script>
  import HowDesktop from "./HowDesktop.svelte";
  import HowMobile from "./HowMobile.svelte";

  let w,
    isMobile = false;

  $: if (w <= 1024) {
    isMobile = true;
  } else {
    isMobile = false;
  }
</script>

{#if isMobile}
  <HowMobile />
{:else}
  <HowDesktop />
{/if}

<svelte:window bind:innerWidth="{w}" />
