<script>
  import { onMount } from "svelte";

  import ServicesPolkaLogo from "./ServicesPolkaLogo.svelte";
  import Ecology from "./Ecology.svelte";
  import Commercial from "./Commercial.svelte";
  import Social from "./Social.svelte";
  import Systems from "./Systems.svelte";
  import StickyNav from "./StickyNav.svelte";

  // Preload images upon entering services component
  const imageArray = new Array();

  const preload = (images) => {
    images.forEach((image, i) => {
      imageArray[i] = new Image();
      imageArray[i].src = images[i];
    });
  };

  const onIntersect = (entries, observer) => {
    if (entries[0].isIntersecting) {
      preload([
        "/assets/img/commercial.png",
        "/assets/img/social.png",
        "/assets/img/systems.png",
      ]);
    }
  };

  let w,
    mounted,
    servicesDots,
    serviceImage,
    fillStyle,
    tabIndex = 0,
    isMobile = false,
    observer,
    servicesContainer;

  let serviceImageDim = { w: 0, h: 0 };

  $: if (servicesContainer && mounted) {
    observer.observe(servicesContainer);
  }

  // fixes dots reverting to default fill for certain interactions
  $: if (w <= 1216 && mounted) {
    isMobile = true;
    if (servicesDots) servicesDots.setAttribute("style", `${fillStyle}`);
    serviceImage.setAttribute("style", "display: none;");
  } else if (mounted) {
    isMobile = false;
    if (servicesDots) servicesDots.setAttribute("style", `${fillStyle}`);
    serviceImage.setAttribute("style", "display: inline-block;");
  }

  // Change dots colour per tab
  $: if (tabIndex === 0 && mounted) {
    fillStyle = "fill: #00ff7d;";
    if (servicesDots) servicesDots.setAttribute("style", fillStyle);
  } else if (tabIndex === 1 && mounted) {
    fillStyle = "fill: #fe5832;";
    if (servicesDots) servicesDots.setAttribute("style", fillStyle);
  } else if (tabIndex === 2 && mounted) {
    fillStyle = "fill: #712bdf;";
    if (servicesDots) servicesDots.setAttribute("style", fillStyle);
  } else if (tabIndex === 3 && mounted) {
    fillStyle = "fill: #00edfd;";
    if (servicesDots) servicesDots.setAttribute("style", fillStyle);
  }

  onMount(() => {
    serviceImage = document.querySelector(".service-image");
    observer = new IntersectionObserver(onIntersect);

    setTimeout(() => {
      requestAnimationFrame(() => {
        mounted = true;
      });
    });
  });
</script>

<style lang="scss">
  @import "../../sass/variables.scss";

  .service-selector {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    min-width: 100%;
    min-height: 100%;
  }

  li {
    margin-bottom: 1.5rem;
  }

  .arrow {
    display: inline-block;
    height: 80px;
    width: 100%;
    white-space: nowrap;
    position: relative;
    text-align: center;
    padding-top: 1em;
    line-height: 1.5em;
    padding-left: 1em;
    padding-right: 1em;
    background: $white;
    color: $black;
    &:after {
      border-right: 40px solid $white;
      transition: all 0.1s cubic-bezier(0.075, 0.82, 0.165, 1);
    }
    transition: all 0.1s cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  .arrow:after {
    // the triangle
    content: "";
    position: absolute;
    border-bottom: 40px solid transparent;
    border-top: 40px solid transparent;
    top: 0;
    height: 0px;
    width: 0px;
    margin-left: -40px;
    left: 0;
  }

  .arrow:hover {
    background: $black;
    color: $white;
    &:after {
      // triangle hover
      border-right: 40px solid $black;
    }
  }

  .hover-orange:hover {
    background: $orange;
    color: $white;
    &:after {
      // triangle hover
      border-right: 40px solid $orange;
    }
  }

  .hover-green:hover {
    background: $green;
    color: $black;
    &:after {
      // triangle hover
      border-right: 40px solid $green;
    }
  }

  .hover-cyan:hover {
    background: $cyan;
    color: $black;
    &:after {
      // triangle hover
      border-right: 40px solid $cyan;
    }
  }

  .hover-purple:hover {
    background: $purple;
    color: $white;
    &:after {
      // triangle hover
      border-right: 40px solid $purple;
    }
  }

  .active-orange {
    background: $orange;
    color: $white;
    &:after {
      // triangle hover
      border-right: 40px solid $orange;
    }
  }

  .active-green {
    background: $green;
    color: $black;
    &:after {
      // triangle hover
      border-right: 40px solid $green;
    }
  }

  .active-cyan {
    background: $cyan;
    color: $black;
    &:after {
      // triangle hover
      border-right: 40px solid $cyan;
    }
  }

  .active-purple {
    background: $purple;
    color: $white;
    &:after {
      // triangle hover
      border-right: 40px solid $purple;
    }
  }

  .columns {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .service-content {
    display: flex;
    align-items: center;
    position: relative;
  }

  .service-content.is-mobile {
    flex-direction: column;
    margin-left: auto;
  }

  .service-content.is-mobile > .service-text {
    margin-top: 4rem;
    margin-bottom: 4rem;
    align-items: center;
  }

  .service-text {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .selector {
    margin-left: calc(40px + 5vw);
  }

  .services-container.is-desktop {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }

  .column {
    position: relative;
  }

  .sticky-container {
    display: flex;
    flex-direction: column;
  }
</style>

<div class="services-container" bind:this="{servicesContainer}">
  <div class:sticky-container="{isMobile}" class:columns="{!isMobile}">
    <div class="column">
      <div
        class="service-image"
        bind:clientHeight="{serviceImageDim.h}"
        bind:clientWidth="{serviceImageDim.w}">
        {#if serviceImageDim.h && serviceImageDim.w}
          <ServicesPolkaLogo bind:servicesDots />
        {/if}
        {#if tabIndex === 0}
          <img
            align="left"
            src="/assets/img/ecology.png"
            alt="Man using a desktop computer" />
        {:else if tabIndex === 1}
          <img
            align="left"
            src="/assets/img/commercial.png"
            alt="Man painting a picture" />
        {:else if tabIndex === 2}
          <img
            align="left"
            src="/assets/img/social.png"
            alt="Woman using a laptop computer" />
        {:else if tabIndex === 3}
          <img
            align="left"
            src="/assets/img/systems.png"
            alt="Man using a laptop computer showing architectural plans" />
        {/if}
      </div>
    </div>
    <div
      class="service-content"
      class:column="{!isMobile}"
      class:is-mobile="{isMobile}">
      {#if tabIndex === 0}
        <div class="service-text">
          <Ecology />
        </div>
      {/if}
      {#if tabIndex === 1}
        <div class="service-text">
          <Commercial />
        </div>
      {/if}
      {#if tabIndex === 2}
        <div class="service-text">
          <Social />
        </div>
      {/if}
      {#if tabIndex === 3}
        <div class="service-text">
          <Systems />
        </div>
      {/if}
    </div>
    {#if isMobile}
      <StickyNav bind:tabIndex />
    {/if}
    {#if !isMobile}
      <div class="column is-narrow selector">
        <div class="service-selector">
          <ul>
            <li>
              <a
                class="arrow hover-green"
                class:active-green="{tabIndex === 0}"
                on:click="{() => (tabIndex = 0)}">
                Data Models
                <br />
                & Academic
              </a>
            </li>
            <li>
              <a
                class="arrow hover-orange"
                class:active-orange="{tabIndex === 1}"
                on:click="{() => (tabIndex = 1)}">
                Commercial
                <br />
                & Retail
              </a>
            </li>
            <li>
              <a
                class="arrow hover-purple"
                class:active-purple="{tabIndex === 2}"
                on:click="{() => (tabIndex = 2)}">
                Social
                <br />
                & Entertainment
              </a>
            </li>
            <li>
              <a
                class="arrow hover-cyan"
                class:active-cyan="{tabIndex === 3}"
                on:click="{() => (tabIndex = 3)}">
                Systems
                <br />
                R&D
              </a>
            </li>
          </ul>
        </div>
      </div>
    {/if}
  </div>
</div>

<svelte:window bind:innerWidth="{w}" />
