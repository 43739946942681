<script>
  import { page, params } from "@roxi/routify";
  import { onMount } from "svelte";

  let articleWidth;
  let backToProjects;

  onMount(() => {
    backToProjects = $params && $params.projects;
    history.replaceState({}, $page.title, location.origin + location.pathname);
  });
</script>

<style lang="scss" global>
  @import "bulma/sass/utilities/_all.sass";
  @import "bulma/sass/elements/content.sass";
  @import "../../sass/variables.scss";

  .blog {
    position: relative;
    top: 0;
    min-height: 100vh;
    background-color: $white;

    .blog-article__outer {
      display: flex;
      justify-content: center;

      .blog-article.content {
        margin: 40px;
        width: 800px;
        max-width: calc(100% - 40px);

        letter-spacing: -0.01em;

        p {
          font-size: 1.25rem;
        }

        // p,
        // blockquote {
        //   margin: {
        //     bottom: 2em;
        //   }
        // }

        // blockquote {
        //   p {
        //     margin: 0;
        //   }
        // }

        a {
          color: $primary;
        }

        strong {
          color: $gray;
        }

        .byline {
          margin: {
            bottom: 60px;
          }

          p {
            text-transform: uppercase;
            font-size: 0.8rem !important;
          }
        }

        .banner-image {
          img {
            width: 100%;
          }
        }

        .large-image {
          padding: 15px;

          img {
            width: 100%;
            border-radius: 8px;
            box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
          }
        }
      }
    }

    .home-button__outer {
      position: relative;
      top: 0;
      z-index: 1;
      pointer-events: none;

      &.sticky {
        position: sticky;
      }

      .home-button {
        padding: 50px;
      }
    }

    .button {
      border: none;
      &:focus {
        color: $white;
      }
      pointer-events: auto;
    }
  }
</style>

<div class="blog">
  <div class="home-button__outer" class:sticky="{articleWidth >= 1300}">
    <div class="home-button">
      {#if backToProjects}
        <a href="/#projects" class="button is-rounded">Back to Home</a>
      {:else}
        <a href="/" class="button is-rounded">Visit Main Site</a>
      {/if}
    </div>
  </div>

  <div class="blog-article__outer" bind:clientWidth="{articleWidth}">
    <div class="blog-article content is-medium">
      <slot />
    </div>
  </div>
</div>
