<script>
  import { onMount } from "svelte";

  import EcologyPolka from "./EcologyPolka.svelte";

  let mounted;
  let w,
    isMobile = false,
    isTablet = false;

  $: if (w <= 1216) {
    isMobile = true;
    if (w >= 768) {
      isTablet = true;
    } else {
      isTablet = false;
    }
  } else {
    isMobile = false;
    isTablet = false;
  }

  onMount(() => {
    setTimeout(() => {
      mounted = true;
    });
  });
</script>

<style lang="scss">
  @import "../../sass/variables.scss";
  @import "./scss/services.scss";
</style>

{#if isMobile}
  <div class="image-container">
    {#if mounted}
      <EcologyPolka fillStyle="fill: #00ff7d;" />
    {/if}
    <div class="image-overlay" class:is-tablet="{isTablet}"></div>
    <img
      width="100%"
      src="/assets/img/ecology.png"
      alt="Man using a desktop computer" />
  </div>
  <br />
{/if}
<div class:service-content-mobile="{isMobile}" class:is-tablet="{isTablet}">
  <h1 class="title text-align">Data Models & Academic</h1>
  <div>
    <p>
      Sometimes there is a great way to do something, but the current way to do
      it is in Excel or on paper. We’ve helped organisations digitise complex
      data models to help automate repetitive tasks, significantly reduce human
      error, and make data-driven insights more accessible to non-technical
      teams. It is much simpler to monitor and optimise something when it lives
      and breathes in the digital realm.
    </p>
    <p>
      Got a quantitative, qualitative, or mixed methods approach or other data
      model you want to make digital? We’re the crew for you.
    </p>
  </div>
  <h1 class="title service-tagline" class:is-mobile="{isMobile}">
    Redesign
    <br />
    <span class="green">our relationship with natural ecosystems.</span>
  </h1>
</div>

<svelte:window bind:innerWidth="{w}" />
