<script>
  import BlurDecorator from "./_BlurDecorator.svelte";
  import { metatags, page } from "@roxi/routify";

  $: metatags.title = `DNA Pathways - ${$page.title}`;
</script>

<style lang="scss" global>
  @import "../sass/global.scss";
</style>

<slot decorator="{BlurDecorator}" />
