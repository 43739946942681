<script>
  import Discover from "./svg/discover/Discover.svg";
  import DiscoverMobile from "./svg/discover/DiscoverMobile.svg";
  import IconExplore from "./svg/discover/IconExplore.svg";
  import IconResearch from "./svg/discover/IconResearch.svg";
  import IconEmpathise from "./svg/discover/IconEmpathise.svg";
  import IconLearn from "./svg/discover/IconLearn.svg";
  import IconInspired from "./svg/discover/IconInspired.svg";

  let w,
    isMobile = false;

  $: if (w <= 1024) {
    isMobile = true;
  } else {
    isMobile = false;
  }
</script>

<style lang="scss">
  @import "../../sass/variables.scss";
  @import "./scss/how.scss";

  .slide-container {
    background-color: $cyan;
  }

  ul.discover-list > li {
    display: flex;
    align-items: center;
    color: $black;
  }

  ul.discover-list > li > * {
    flex: 1;
    padding: 1rem;
  }

  .discover-list-heading {
    font-weight: bold;
  }

  .svg-expand {
    padding: 0 5vw;
  }
</style>

<div class="slide-container">
  <div class="svg-flex-container" class:mobile-container="{isMobile}">
    {#if isMobile}
      <div class="section">
        <h6 class="title is-6 section-title">HOW WE WORK</h6>
      </div>
      <h1 class="title is-huge is-huge-mobile">Discover</h1>
      <div class="svg-expand">
        {@html DiscoverMobile}
      </div>
      <div class="section">
        <ul class="discover-list">
          <li>
            {@html IconExplore}
            <div>
              <span class="discover-list-heading">Explore.</span>
              <p>Look, feel, sense, try, experience...Define the challenge.</p>
            </div>
          </li>
          <li>
            {@html IconResearch}
            <div>
              <span class="discover-list-heading">Research.</span>
              <p>See from multiple perspectives to find what truly matters.</p>
            </div>
          </li>
          <li>
            {@html IconEmpathise}
            <div>
              <span class="discover-list-heading">Empathise.</span>
              <p>Resonate deeply with someone or something.</p>
            </div>
          </li>
          <li>
            {@html IconLearn}
            <div>
              <span class="discover-list-heading">Learn.</span>
              <p>Put the pieces together in a new and creative way.</p>
            </div>
          </li>
          <li>
            {@html IconInspired}
            <div>
              <span class="discover-list-heading">Be Inspired.</span>
              <p>Gain knowledge and experience for design.</p>
            </div>
          </li>
        </ul>
      </div>
    {:else}
      {@html Discover}
    {/if}
  </div>
</div>

<svelte:window bind:innerWidth="{w}" />
